<template>
  <div class="formation">
    <liste_membre/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import liste_membre from '../components/l_membre.vue'

export default {
  name: 'Membreliste',
  components: {
    liste_membre
  }
}
</script>
